.breadcrumb {
    background-color: fade(@c-dark, 50%);
    padding: 13px 0;
    position: relative;
    // position: sticky;
    // top: 80px;
    // top: @header-height;
    top: 0;
    z-index: 90;
    color: #FFF;
    min-height: @breadcrumb-height;
    // @media @tablet {
    //     top: 0;
    // }
}

.breadcrumb__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}   

.breadcrumb__list-item {
    display: inline-block;
    font-size: .7rem;
    text-transform: uppercase;
    &:after {
        content: '>';
        font-size: .6rem;
        margin: 0 5px;
    }
}

.breadcrumb__list-item--active {
    text-decoration: underline;
    &:after {
        display: none;
    }
}

.breadcrumb__link {
    text-decoration: none;
}