﻿.boardmember-box {
    padding-bottom:10px;
    max-width:800px;
    margin:0 auto;
}
.boardmember__header {
    display: flex;
    justify-content:space-between;
    background-color: #EBEBEB;
    width:100%; 
    min-height: 120px;
    cursor: pointer;
    @media @mobile {
        /*max-height:100px;
        min-height:100px;*/
    }
    &:hover {
        .boardmember__button {
            background-color: @c-body;
            color: #FFF;
            border-color: @c-body;
        }
    }
}
.boardmember__title {
    margin: 0 0 5px;
    font-weight: 100;
    padding-right: 10px;
    font-size: 1.6rem;
    line-height: 1;
    @media @mobile {
        font-size:1.1rem;
    }   
}
.boardmember__titles {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0 10px 15px;
}
.boardmember__img {
    
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position:center; 
    
}
.boardmember__img--container {
    min-width: 120px;
    max-width: 120px;
    max-height: 120px;
    min-height: 120px;

    @media @mobile {
        /*min-width: 100px;
        max-width: 100px;
        max-height: 100px;
        min-height: 100px;*/
    }
}

.boardmember__button {
    cursor: pointer;
    background-color: transparent;
    border: solid 1px white;
    text-transform: uppercase;
    width: 120px;
    min-height:24px;
    color: @c-body;
    font-size: 0.75rem;
    padding: 5px 5px 5px 5px;
    min-width: 80px;
    word-wrap: break-word;
    margin: 0;
    .transition;
    &:after {
        content: '\e904';
        font-family: 'icomoon';
        top: 50%;
        right: 14px;
        transform: translateY(-50%) rotate(270deg);
        position: absolute;
        pointer-events: none;
    }
}

.boardmember-box.open .boardmember__button {

    &:after {
        content: '\e906';
    }
}

.boardmember__content {
    max-height: 0;
    overflow: hidden;
    transition: all ease .4s;
}

.boardmember-box.open .boardmember__content {
    max-height: 1000px;
    padding: 20px 0;
}

.small-heading {
    padding-top: 15px;
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    text-transform: uppercase;
    padding:0;
    margin:0;
    float:left;
    padding-bottom: 40px;
    
}

.heading-container{
   
    padding:40px 0px 35px 0px;
    margin: 0 auto; 
    max-width:800px;
    
    

}