.related-links {
    background-color: @c-super-light-grey;
    margin: 30px 0;
    padding: 30px;
}

.related-links__container {
    .container;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.related-links__link {
    // flex: 0 1 20%;
    text-align: center;
    position: relative;
    padding-left: 20px;
    margin: 5px 20px;
    &:before {
        content: '\f054';
        font-family: 'fontAwesome';
        position: absolute;
        font-size: .7rem;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}