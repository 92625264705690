﻿.panel-grid {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
    grid-auto-rows: minmax(205px, max-content);
    margin: 30px 0;
    @media @tablet {
        // grid-template-columns: 1fr 1fr 1fr;
        grid-auto-columns: auto;
    }
    @media @mobile {
        grid-template-columns: auto;
    }
}

.panel-grid__panel {
    position: relative;
    overflow: hidden;
    background-color: #C3C4C4;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    // grid-template-columns: 1fr 1fr 1fr;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFF;



    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        pointer-events: none;
        opacity: 0;
        .transition;
    }

    &:hover {
        &:after {
            opacity: .15;
        }
    }

    @media @mobile {
        grid-column: span 2;
    }
}
.panel-grid__withBackground {
    padding: 25px 10px 25px 10px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000;
        pointer-events: none;
        opacity: 0.1;
    }

    .panel-grid__heading {
        padding: 5px 0;
        display: inline;
        line-height: 1.3;
        background-color: #000;
        box-shadow: 10px 0 0 #000, -10px 0 0 #000;
        position: absolute;
        top: 0;
        left: 0;


        &--bottom-left {
            top: unset;
            bottom: 0;
        }

        &--bottom-right {
            top: unset;
            bottom: 0;
            right: 0;
            text-align: right;
            left:unset; 
        }

        &--top-right {
      text-align: right;
            right:0;
            left: unset;
        }
    }

    .panel-grid__date {
        background-color: #000;
        box-shadow: 10px 0 0 #000, -10px 0 0 #000;
        display: inline;
        align-self: baseline;
    }
}

.c-sport--bg.panel-grid__withBackground {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000;
        pointer-events: none;
        opacity: 0.1;
    }
    color:#FFF;
}
// Sizes
.panel-grid__panel--large {
    grid-row: span 2;
    grid-column: span 2;
}

.panel-grid__panel--wide {
    grid-column: span 2;
}

.panel-grid__panel--tall {
    grid-row: span 2;
}


// Modifiers
.panel-grid__panel--logo {
    text-align: center;
    .panel-grid__heading {
        font-weight: 400;
    }
}

.panel-grid__content {
    flex: 1;
    margin-bottom: 10px;
    position: relative; 
}

.panel-grid__heading {
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    font-size: 1.625rem;
    line-height: 1.1;
}

.panel-grid__text {
    font-size: 1.2rem;
}

.panel-grid__date {
    font-size: 1.2rem;
    font-weight: 600;
}

.panel-grid__icon {
    width: 100%;
    max-width: 60px;
    align-self: center;
    margin-bottom: 10px;
}

.panel-grid__category {
    align-self: flex-start;
    background-color: #FFF;
    font-weight: 600;
    padding: 7px 10px;
    border-radius: 3px;
    font-size: 0.75rem;
    min-width: 81px;
    text-align: left;
    margin-top: 10px;
    color: @c-body;
    text-transform: uppercase;
    letter-spacing: 1px;
}

