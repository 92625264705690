.social-buttons {
    margin-top: 20px;

    &--main{

        margin: 20px 0;
        .social-buttons__link{
            border-radius: 5px;
            height: 40px;
            width: 40px; 
            
            .fa{
                font-size: 2rem;
            }

        }
    }
}

.social-buttons__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.social-buttons__list-item {
    margin-right: 5px;
    &:last-of-type {
        margin-right: 0;
    }
}

.social-buttons__link {
    display: block;
    background-color: @c-dark-blue;
    color: #FFF;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    text-decoration: none;
    position: relative;
    text-indent: -99999px;
    overflow: hidden;
}

.social-buttons__icon {
    text-indent: 0;
}
.social-buttons__icon-img{
    width: 25px;
}
.footer__container {
    .social-buttons__icon-img {
        width: 14px;
    }
}