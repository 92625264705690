﻿.venue__details--panel {
    background-color: #F1F1F1;
    padding: 1px 15px 35px 15px;

    @media @desktop {
        /*padding: 1px 0px 35px 0px;*/
        padding-left: 0;
    }

    @media @tablet {
        /*padding: 1px 0px 35px 0px;*/
        padding-left: 0;
        padding-right: 0;
    }

    @media @mobile {
        padding-left: 0;
        padding-right: 0;
    }

}

.venue__details--panel.venue-mobile {
    display: none;

    @media @mobile {
        display: block;
    }
}

.venue__details--panel.venue-desktop {
    display: block;

    @media @mobile {
        display: none;
    }
}
    .venue__details--list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 15px 0px 0px 0px;
        list-style-type: none;

        @media @desktop {
            justify-content: flex-start;
            padding-top: 25px;
        }

        @media @tablet {
            padding-top: 25px;
        }

        @media @mobile {
            display: block;
            width: 100%;
            padding: 0px 0px 15px 0px;
            flex-direction: column;
            padding-top: 25px;
        }
    }

    .venue__details--item {
        background-color: #FFFFFF;
        padding: 10px 10px 10px 10px;
        max-width: 500px;
        border-radius: 6px;
        min-height: 100px;
        flex: 1 0 15%;
        margin: 0 5px;
        color: #3E3B37;
        flex-wrap: wrap;

        @media @desktop {
            flex: 1 1 ~"calc(33.333% - 15px)";
            margin-bottom: 10px;
            min-height: 300px;
        }

        @media @tablet {
            flex: 1 1 ~"calc(50% - 25px)";
            max-width: 700px;
            margin-bottom: 20px;
        }

        @media @mobile {
            margin-bottom: 20px;
            flex: 1 1 100%;
            max-width: 700px;
            word-break: break-all;
        }
    }

    .venue__details--header {
        border-bottom: 1.3px solid #0D1E46;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .venue__details--heading {
        font-size: 0.9rem;
        font-weight: 600;
        margin: 0;
        color: #3B3B3A;
        text-transform: uppercase;
    }

    .venue__details--subheading {
        font-size: 1rem;
        margin: 0;
        text-transform: uppercase;
    }

    .venue__details--days {
        font-size: 1rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;
    }

    .venue__details--subList {
        list-style-type: none;
        padding: 0;
    }

    .venue__details--subItem {
        border-top: 1.3px solid #BFB9B2;
        margin-top: 5px;
        min-height: 20px;

        &:first-of-type {
            border-top: 0;
        }
    }

    .facility__list--subItem {
        border-top: 1.3px solid #BFB9B2;
        margin-top: 5px;
        min-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;

        &:first-of-type {
            border-top: 0;
        }
    }

    .facility__icon {
        font-size: 0.8rem;
        color: #3B3B3A;
    }

    .venue__gettingHere {
        padding: 15px;
    }

    .venue__gettingHere--heading {
        padding-top: 15px;
        text-align: center;
        font-size: 2rem;
        font-weight: 100;
        text-transform: uppercase;
    }

    .venue__gettingHere--text {
        p {
            font-size: 1.25rem;
            text-align: left;
            margin: 0 70px;

            @media @mobile {
                margin: 0 5px;
            }
        }
    }

    .venueMapPanel {
        max-width: 1100px;
        padding: 35px 70px 5px 70px;
        min-width: 100px;
        margin: 0 auto;

        @media @mobile {
            padding: 35px 15px 5px 15px;
        }
    }

    #map__container {
        width: 100%;
        height: 400px;

        @media @mobile {
            max-height: 200px;
        }
    }

    .autocomplete-items div:hover {
        background-color: #e9e9e9;
        cursor: pointer;
    }

    .autocomplete-active {
        background-color: #e9e9e9;
    }

    .facility__list--item {
        display: flex;
        justify-content: space-between;
    }
    /*.panel__heading--text {
     margin: 0;
     padding: 0 20px;
     text-align: center;
     font-size: 1.3rem;
     text-transform: uppercase;
 }*/

