header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .transition;
    // @media @tablet {
    //     position: relative;
    // }
}

// .header--small {
//     .header__container {
//         &:before {
//             transform: scaleY(.6);
//             // box-shadow: 0 0 40px 0 rgba(0,0,0,.1);
//         }
//     }
//     nav {
//         top: -53px;
//         @media @desktop {
//             top: 0;
//         }
//     }
//     .nav__dropdown {
//         top: 80px;
//         @media @desktop {
//             top: 0;
//         }
//     }
//     .header__logo {
//         transform: scale(.7) translateY(-35px);
//     }
//     .menu-trigger {
//         transform: translateY(-10px);
//     }
// }

.header--small {
    position: fixed;
    top: 0;
    transform: translateY(-120%);
    transition: all ease .4s;

    @media @tablet {
        display: none;
    }

    .header__container {
        height: @header-small;
    }

    .header__logo {
        width: 100px;
    }

    .nav__dropdown {
        top: @header-small;
    }
}

.header--small--show {
    transform: translateY(0);
    box-shadow: 0 0 40px 0 rgba(0,0,0,.1);

    .search-container {
        display: none;
    }
}

.header__container {
    .container;
    position: static;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @header-height;
    .transition;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        // background: red;
        transform-origin: top;
        z-index: -1;
        .transition;
    }

    @media @desktop {
        height: 135px;
        padding-top: 35px;
    }
}

.header__logo {
    width: 150px;
    height: 73px;
    will-change: transform;
    transform-origin: center;
    will-change: transform;
    .transition;

    &:hover {
        transform: scale(.95);
    }

    @media @mobile {
        width: 120px;
        height: auto;
    }

    &--dean {
        height: 97px;
        width: auto;
    }
}

.dean-castle {
    .header--small {
        .header__container {
            height: 100px;
        }
    }
}