.transition {
	transition: all .2s ease;
}

.transition--ease {
	transition: all cubic-bezier(.75, .02, .38, 1.05) .3s;
}


.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin {
	margin: 0;
}

.no-margin--top {
	margin-top: 0;
}

.hidden {
    display: none;
}

.p--reset {
	font-size: inherit;
	margin: 0;
	line-height: inherit;
}


.link--cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	//pointer-events: none;
}
 
.no-underline {
	text-decoration: none;
}

.no-scroll {
	overflow: hidden;
}
.text-center{
    text-align:center;
}

.label--hide {
	position: absolute;
	left: -99999px;
}