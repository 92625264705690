
h1, h2, h3, h4, h5, h6 {
  margin:30px 0;
  position: relative;
  line-height: 1;
  font-family: @font-sans-serif;
  font-weight: 600;
  &:first-of-type {
    margin-top: 0;
  }
}

h1 {
  font-size: 4rem;
  @media @mobile {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2.5rem;
  @media @mobile {
    font-size: 2rem;
    line-height: 1.3;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.3;
  @media @mobile {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 1.5;
}

p {
  margin: 30px 0;
  font-size: 1rem;
  line-height: 1.5;
  overflow-wrap:break-word; 

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &:empty {
    margin: 0;
  }
}

.text--large {
  font-size: 1.75rem;
  line-height: 1.3;
}

.heading--small{
text-transform: uppercase;
text-decoration: underline;
}

a {
    color: inherit;
    // text-decoration: none;
} 
.basic__content{
    li{
        font-size: 1.25rem;
    }
}