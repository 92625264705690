#colorbox {
    outline: none;
    z-index: 102;
}

#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.5;
    background: #555050;
    z-index: 101;
}

#popOver {
    border-radius: 15px;
    border: 1px solid #cfc6c6;
    text-align: center; 
    left: -3000px;
    background-color: #fff;
    padding: 0.5em; 
    outline: none;
    z-index: 102;
    height: 100%;
}

#popOver .btn {
    display: block;
    width: 90%;
    text-align: center;
    background-color: #7ac143;
    color: #fff;
    margin: 0.5em auto;
    height: 30px;
}

#popOver .btn-close {
    width: 70%;
    background-color: #00a2e5;
}
