.top-nav {
    flex: 0 1 700px;
    align-self: flex-end;
    margin-bottom: 20px;
    // position: relative;
    top: 0;
    will-change: top;


    @media @desktop {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        // height: 100vh;
        height: 100%;
        // background-color: @c-dark;
        background-color: #FFF;
        // color: #FFF;
        transform: translateX(100%);
        padding: 50px;
        margin: 0;
        overflow-y: auto;
        .transition;
    }

    @media @mobile {
        padding: 30px;
    }
}

.nav--open {
    @media @desktop {
        transform: translateX(0);
    }
}

.nav__item-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    @media @desktop {
        display: block;
    }
}

.nav__item {
    // position: relative;
    display: flex;
    &.whats-on {
        .nav__link {
            &:after {
                border-color: @c-whatson;
            }
        }
        .nav__dropdown {
            background-color: @c-whatson;
            color: #FFF;
        }
    }
    &.venues {
        .nav__link {
            &:after {
                border-color: @c-venues;
            }
        }
        .nav__dropdown {
            background-color: @c-venues;
            color: #FFF;
        }
    }
    &.libraries {
        .nav__link {
            &:after {
                border-color: @c-libraries;
            }
        }
        .nav__dropdown {
            background-color: @c-libraries;
            color: #FFF;
        }
    }
    &.culture {
        .nav__link {
            &:after {
                border-color: @c-culture;
            }
        }
        .nav__dropdown {
            background-color: @c-culture;
            color: #FFF;
        }
    }
    &.countryside-outdoor {
        .nav__link {
            &:after {
                border-color: @c-countryside;
            }
        }
        .nav__dropdown {
            background-color: @c-countryside;
            color: #FFF;
        }
    }
    &.sport-fitness {
        .nav__link {
            &:after {
                border-color: @c-sport;
            }
        }
        .nav__dropdown {
            background-color: @c-sport;
        }
    }
    @media @desktop {
        display: block;
    }

}

.nav__item--parent {
    margin: 0 10px;
    flex: 0 1 100% / 6;
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    @media @desktop {
        margin: 0;
    }
    .nav__link {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 2px solid @c-light-grey;
            transform-origin: top;
            .transition;
            transition-delay: .1s;
        }
    }
}
  
.nav__item--has-dropdown {
    &:hover { 
        @media only screen and (min-width : 992px) {
            .nav__link {
                &:after {
                    transform: scaleY(11);
                }
            }
        }
        >.nav__dropdown {
            transform: translateY(0);
        }
    }
}


.nav__item--current {
    @media only screen and (min-width : 992px) {
        .nav__link {
            &:after {
                transform: scaleY(11);
            }
        }
    }
}

.nav__link {
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    flex: 1;
    font-size: 1.25rem;
    // text-transform: lowercase;
    line-height: 1.1;
    will-change: transform;
    position: relative;
    text-decoration: none;
    width: 100%;
    .transition;
    @media @desktop {
        padding: 10px 0;
    }
}

.nav__link--dropdown {
    padding: 10px 0;
}


.nav__dropdown {
    background-color: @c-light-grey;
    position: absolute;
    top: @header-height;
    left: 0;
    right: 0;
    padding: 50px 0;
    z-index: -2;
    transform: translateY(-200%);
    will-change: transform;
    display: flex; 
    .transition--ease; 
    .nav__link {
        background-color: rgba(255,255,255,.2);
        padding: 5px 10px;
        display: inline-block;
        @media @desktop { 
            background: none;  
            padding: 5px 0; 
            display: block; 
        }
    } 
    @media @desktop {
        position: relative;
        transform: none;
        box-shadow: none; 
        background: none !important; // sorry!
        top: auto;
        left: auto;
        right: auto;
        padding: 0; 
        z-index:1; 
    }
}

.nav__dropdown-container {
    .container;
    display: flex;
    @media @desktop {
        color: @c-body;
    }
}

.nav__item-container {
    column-count: 2;
    flex: 1;
    @media @desktop {
        column-count: 1;
    }
}

.nav__item--dropdown {
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
}

.nav__dropdown-img {
    max-width: 400px;
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    margin-left: 30px;
    @media @desktop {
        display: none;
    }
}



.menu-trigger {
   z-index: 101;
   position: fixed;
   top: 30px;
   right: 20px;
   display: none;
   height: 40px;
   width: 40px;
   border-radius: 5px;
   cursor: pointer;
   order: 1;
   background-color: @c-blue;
   .transition;
   @media @desktop {
      display: block;
      margin-top:35px; 
   }
   span.line {
      display: block;
      width: 24px;
      height: 3px;
      background: #FFF;
      border-radius: 10px;
      position: relative;
      top: 18px;
      left: 8px;
      &:before,
      &:after {
         content: '';
         display: block;
         width: 24px;
         height: 3px;
         background: #FFF;
         border-radius: 10px;
         position: absolute;
         cursor: pointer;
         .transition;
      }
      &:before {
         top: -8px;
      }
      &:after {
         bottom: -8px;
      }
   }

}

.menu-trigger--active {
    span.line {
       background: transparent;
       &:before {
          transform: rotate(45deg);
          top: 0;
       }
       &:after {
          transform: rotate(-45deg);
          bottom: 0;
       }
    }
 }

#topHamburger {
    &:hover {
        cursor: pointer;
    }
}

#extraDropdown{

    @media @desktop{
        margin-top: 20px;
        border-top: solid 2px lightgrey;
      //  border-bottom: solid 1.5px lightgrey;
        
    }
    &.active{
        transform: translateY(0);
    }
    .nav__dropdown-container{
        @media @desktop{
            padding:  10px 0;
        }
    }

}

.extra-nav__trigger {
    z-index: 101;
  //  position: fixed;
   // top: 30px;
   // right: 20px;
   // display: none;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    cursor: pointer;
    order: 1;
  //  background-color: @c-blue;
    .transition;

    @media @desktop {
        display: none;
        margin-top: 35px;
    }

    span.line {
        display: block;
        width: 14px;
        height: 2.5px;
        background: @c-body;
        border-radius: 10px;
        position: relative;
        top: 18px;
        left: 8px;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 14px;
            height: 2.5px;
            background: @c-body;
            border-radius: 10px;
            position: absolute;
            cursor: pointer;
            .transition;
        }

        &:before {
            top: -8px;
        }

        &:after {
            bottom: -8px;
        }
    }
}