footer {
    border-top: 5px solid @c-light-grey;
    //    margin-top: 50px;
    padding: 50px 0;
}

.footer__container {
    .container;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media @desktop {
        justify-content: space-between;
    }

    @media @mobile {
        display: block;
    }
}

.footer__logo-link {
    // float: left;
    margin-right: 20px;
    display: block;

    @media @mobile {
        flex: 0 1 100%;
        margin: 0 auto 30px;
        text-align: center;
    }
}

.footer__logo {
    .header__logo;
}

.footer__col {
    flex: 0 1 180px;
    margin-right: 20px;

    &:last-of-type {
        margin-right: 0;
    }

    @media @desktop {
        flex: 1;
        margin-bottom: 30px;
    }

    @media @mobile {
        margin: 0 0 30px;
    }
}

.footer__col--large {
    flex: 0 1 400px;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    @media @desktop {
        flex: 0 1 100%;
    }
}

.footer__heading {
    /*background-color: @c-light-grey;*/
    background-color: #6d6d6d;
    color: #FFF;
    text-transform: uppercase;
    font-size: .8rem;
    padding: 7px 15px;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.footer__links {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer__link-item {
    border-bottom: 2px solid @c-light-grey;
}

.footer__link {
    padding: 6px 0;
    display: block;
    font-size: 0.875rem;
    text-decoration: none;
}

// Footer nav override
.nav__item-list--footer {
    display: block;
    flex: 1;

    .nav__item {
        margin: 0;
    }

    .nav__link {
        padding: 7px 0;
        font-size: 1rem;
    }
}

.dean-castle {
    .footer__logo {
        height: 90px;
    }
}