﻿.subnav {
    background-color: #EBEBEB;
    padding: 30px 0;
    margin: 30px 0;
}

.subnav--dark {
    background-color: @c-dark;
    .subnav__item {
        background-color: #FFF;
        
    }
    .subnav__content {
        color: @c-dark;
    }
    .section-heading__text {
        color: #FFF;
    }
}

.subnav__item--list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;

    .owl-stage-outer {
        /*overflow: visible !important;*/
    }

    .owl-stage {
        padding-left: 0 !important;
        display: flex;
        justify-content: center;
        // width: 100% !important;
        min-width: 100%;

        @media @mobile{
            justify-content:flex-start;
        }
    }

    .owl-item {
        flex: 0 1 250px;
        display: flex;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        max-width: 300px;
    }
}

.subnav__item {
    background-color: #3B3B3A;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    min-height: 100px;
    height: 100%;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        opacity: 0;
        pointer-events: none;
        .transition;
    }

    &:hover {
        &:after {
            opacity: .15;
        }
    }
}
.subnav__content {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 500;
}

 .subnav__icon {
     width: 25px;
     margin: 15px auto 0;
     display: block;
     color: #FFF;
 }

.subnav-heading {
    display: flex;
    align-items: center;
    margin: 0 auto 20px;
    justify-content: space-between;
    max-width: @container-width;
    width: 100%;

    &:before, &:after {
        content: '';
        border-top: 2px solid @c-light-grey;
        height: 2px;
        flex: 0 1 230px;
        display: block;
        margin-top: -15px;

        @media @mobile { 
            flex: 0 1 100px;
        } 
    }
}

.subnav-heading__text {
    margin: 0;
    padding: 0 20px;  
    font-size: 1rem; 
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align:center; 
}

.owl-next {
    background: none;
    color: darkgray;
    font-size: 50px;
    position: absolute;
    right: -60px;
    top: 20%;

    @media @tablet {
        right: -45px;
    }

    @media @mobile {
        right: -40px;
    }
}
.owl-prev { 
    background: none;
    color: darkgray;
    font-size: 50px;
    position: absolute;
    left: -60px; 
    top: 20%; 

    @media @tablet {
        left: -45px;
    }

    @media @mobile {
        left: -40px;
    }
}
/*.owl-prev.disabled{
    background: none;
    color: lightgrey;
    font-size: 50px;
    position: absolute;
    left: -60px;
    top:20%;
}
.owl-next.disabled {
    background: none;
    color: lightgrey;
    font-size: 50px;
    position: absolute;
    right: -60px;
    top: 20%;
}*/