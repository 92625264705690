input,
textarea,
select {
    background-color: #FFF;
    border: 0;
    padding: 13px;
    outline: none;
    font-family: inherit;
    margin-bottom: 20px;
    display: block;
    font-size: 1rem;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0px;

    &::-webkit-input-placeholder {
        color: #555555;
    }

    &::-moz-placeholder {
        color: #555555;
    }

    &:-ms-input-placeholder {
        color: #555555;
    }

    &:-moz-placeholder {
        color: #555555;
    }

    &::-ms-expand {
        display: none; 
    }
}

input[type=radio], input[type=checkbox] {
    margin: 0;
    background: none;
    padding: 0; 
    display: inline;
}

input[type=radio] {  
    -webkit-appearance: radio;
}

input[type=checkbox] {
    -webkit-appearance: checkbox;
}

.input-container {
    display: flex;
    margin-bottom: 10px;
    border-radius: 7px;
    // overflow: hidden;
    max-width: 100%;
    position: relative;
}

.input-container--wide {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.input-container--no-flex {
    display: block;
    .input-container__field {
        width: 100%;
    }
}

.input-container__label {
    background-color: @c-blue;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .7rem;
    width: 50px;
    border-radius: 7px 0 0 7px;
}

.input-container__label--dark {
    background-color: @c-whatson;
}

.input-container__label--full {
    background: none;
    padding: 0;
    color: @c-body;
    width: 100%;
    margin-bottom: 10px;
}

.input-container__field {
    margin-bottom: 0;
    flex: 1;
    border-radius: 7px;
    &--no-border-left {
        border-radius: 0 7px 7px 0;
    }
    &--no-border-right {
        border-radius: 7px 0 0 7px;
    }
}

.input-container__submit {  
    // flex: 1;
    background-color: @c-blue;
    margin: 0;
    border-radius: 7px;
    color: #FFF;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    &--no-border-left {
        border-radius: 0 7px 7px 0;
    }
}

.input-container__submit--fixed {
    width: 50px;
    overflow: hidden;
    text-indent: -9999px;
    background-image: url(/img/icons/map_pin.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}

.select-container {
    position: relative;
    width: 100%;
    // max-width: 400px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 50px;
        bottom: 0;
        border-right: 1px solid @c-blue;
        pointer-events: none;
    }
    &:after {
        content: '\f078';
        font-family: 'fontAwesome';
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        position: absolute;
        color: #FFF;
        pointer-events: none;
    }
    
}

.select-container--yellow {
    &:before {
        border-color: @c-body !important;
    }
    &:after {
        color: @c-body;
    }
    .select-container__field {
        background-color: @c-yellow;
        color: @c-body;
    }
}

.select-container__field {
    appearance: none;
    outline: none;
    color: @c-body;
    cursor: pointer;
    width: 100%;
    .transition;
    background-color: @c-dark;
    color: #FFF;
    border-radius: 7px;
    padding-right: 60px;
}

.contact-form {
    background-color: #efefef;
    padding: 30px;
    margin: 30px auto;
    max-width: 500px;
}

.umbraco-contact-form {
    .contact-form;
    input[type=submit] {
        .input-container__submit;
    }
    input[type=text], textarea {
        .input-container__field;
    }
    label {
        .input-container__label--full;
        margin: 15px 0;
    }
    h4.umbraco-forms-caption {
        margin-bottom: 0;
        line-height: 1 !important;
    }
}

.form-input {
    border: 1px solid lightgrey;
    border-radius: 5px;
    height: 30px;
    margin-top: 5px;
    width:25%;  
    @media @tablet{
        width:100%; 
    }
}
#btnsubmit{
    margin-left:0;
}

.umbraco-forms-container{
    .umbraco-forms-field{ 
        .umbraco-forms-field-wrapper{
            .checkboxlist{
                display: flex; 
                flex-direction: column;
                label{
                    margin: 0 10px;
                }
                .checkboxlist-item{
                    display: flex;

                    input[type=checkbox]
                    {
                        margin-top: 5px;
                    }
                }
            }
               
        }
    }
}