﻿.folder-container {
    margin-bottom:15px;
    
}

.folder-header {
    display: flex;
    justify-content: space-between;
    align-content:center;
    background-color: #EBEBEB;
    padding-left: 10px;
    width: 100%;
    cursor: pointer;

}

.folder-heading {
    display: inline-block;
    transform: translateY(25%);
    
}

.folder-header__text {
    font-size: 1rem;
    float:left;
   /*transform: translateY(50%);*/
    /*&:after {
        content: '\e904';
        font-family: 'icomoon';
        top: 50%;
        transform: translateY(-50%) rotate(270deg);
        position: absolute;
        color: #3B3B3A;
        pointer-events: none;
    }*/
}

.file-list__container {
}

.file-list{
    list-style-type:none;
}

.file-list__item {
    cursor: pointer;
}

.folder-container.open .file-list__container {
    display: block;
    padding: 5px;
}

.folder-container.closed .file-list__container{
    display:none; 
}

/*.document-icon {
 
    transform: rotate(270deg);
    
}*/

.download-link{
    text-decoration:none;
}

.folder-container.open .document-icon {
    transform:rotate(90deg) translateY(35px);

    
    }

.folder-container.closed .document-icon {
    transform: rotate(270deg); 
}

.file-count {
    /*transform: translateY(25%);*/
    font-size: 1rem;
    float: right;
}