img, iframe {
   max-width: 100%;
   border: 0;
}

.section-heading {
   display: flex;
   align-items: center;
   margin: 30px 0;
  


   &:before, &:after {
      content: '';
      border-top: 2px solid @c-light-grey;
      height: 2px;
      flex: 1;
      display: block;
      
   }
}

.section-heading--large {
    display:flex;
    justify-content: center;
    &:before, &:after {
        display: none;
    }
    .section-heading__text {
        font-size: 2.25rem;
        letter-spacing: 0;
      
    }
}

.section-heading__text {
    margin: 0;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.panel-heading {
    display: flex;
    align-items: center;
    margin: 0px auto;
    justify-content: space-between;
     
    &:before, &:after {
        content: '';
        border-top: 2px solid #98918B;
        height: 2px;
        flex: 1;
        display: block;
        max-width: 200px;
        margin-top: -20px;
    } 
}

.sub-heading__text{
    
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    text-transform: uppercase;
}

.panel-heading__text {
    padding: 0px 20px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 20px 0; 
}

.details-panel__heading{
    border-bottom: 2px solid @c-light-grey;
    padding-top:15px;
    display:flex;
    justify-content:center; 
}

.details-heading__text {
    margin: 0;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    
}

.header-banner {
    width: 100%;
    height: 500px;
    margin-top: -@breadcrumb-height;
    background-color: @c-light-grey;
    position: relative;
    @media @desktop-wide {
        padding-top: 43%;
        height: auto;
    }
    @media @tablet {
        padding-top: 60%;
    }
}
.header-banner-venue {
    width: 100%;
    height: 500px;
    margin-top: -@breadcrumb-height;
    background-color: @c-light-grey;
    position: relative;

    @media @desktop-wide {
        padding-top: 43%;
        height: auto;
    }

    @media @tablet {
        padding-top: 60%;
    }
}

.header-banner__img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1000px;
    object-fit: cover;
    height: 100%;
}

.image__fullwidth{
    width: 100%;
    max-height:375px;
    object-fit:cover;
}

.basic__content {
    /*padding: 50px 0px;*/
    margin: 50px auto;
    max-width: 1000px;
}

.basic__content--full {
    max-width: none;
}

.basic__content--heading {
    color: @c-dark;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 30px;
    position: relative;
    text-align: center;
    font-size: 3rem;
    letter-spacing: 1px;
    font-weight: 400;
    @media @mobile {
        font-size: 2rem;
    }
    &:after {
        content: ''; 
        position: absolute;
        left: 50%;
        bottom: 0;
        border-top: 2px solid @c-light-grey;
        width: 150px;
        transform: translateX(-50%);
    }
}

.basic__content--mainText {
    p {
        font-size: 1.25rem;
        text-align:center;
    
        // margin: 0 20px;
    }
}
.responsive__video{
    }


.smaller-image {
    display: inline-block;
    margin-left: 20px;
}

a[href^="mailto:"]:not(.event-email) {
    text-decoration: none;
    background-color: transparent;
    color: #000;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    position: relative;
    margin: 0 5px 10px;
    border: solid lightgrey .5px;
    outline: none;
    font-size: 1.1rem;
    cursor: pointer;

    &:before {
        content: "\f0e0";
        font-family: 'fontAwesome';
        margin-right: 10px;
        color:black;
    }
}

