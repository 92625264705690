.button, button {
    text-decoration: none;
    background-color: #6d6d6d;
    color: #FFF;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    position: relative;
    margin: 0 5px 10px;
    border: 0;
    outline: none;
    font-size: 1.1rem;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        opacity: 0;
        pointer-events: none;
        .transition;
    }
    &:hover {
        &:before {
            opacity: .2;
        }
    }
    a {
        text-decoration: none;
    }
}