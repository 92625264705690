.calendar {
    background-color: @c-super-light-grey;
    padding: 50px 0;
}

.calendar-filter {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
    @media @mobile {
        display: block;
    }
}

.calendar-filter__col {
    // flex: 0 0 auto;
    flex: 1;
    border-left: 2px solid #9D9D9D;
    border-right: 2px solid #9D9D9D;
    padding: 0 15px;
    margin: 0 -1px;
    &:first-of-type {
        border-left: 0;
        padding-left: 0;
        margin-left: 0;
    }
    &:last-of-type {
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
    }
    @media @desktop-wide {
        &:first-of-type {
            border: 0;
            padding-right: 0;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        &:nth-of-type(2) {
            border-left: 0;
            padding-left: 0;
        }
    }
    @media @tablet {
        flex: 1 0 50%;
        margin: 20px -1px 0;
        padding-bottom: 0 !important;
        border: 0 !important;
        // padding-top: 10px;
    }
    @media @mobile {
        padding: 0 !important;
    }
}

.calendar-filter__col--large {
    flex: 0 0 400px;
    // flex: 1 0 40%;
    @media @desktop-wide {
        flex: 0 0 100%;
    }
}

.calendar-filter__heading {
    margin: 0 0 20px;
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
}

.calendar-filter__button-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.calendar-filter__button {
    .button;
    flex: 0 1 ~"calc(50% - 10px)";
    opacity: .9;
    justify-content: flex-start;
    /*@media @desktop-wide {
        flex: 0 1 ~"calc(33.3333% - 10px)";
    }*/
    @media @desktop-wide {
        flex: 0 1 ~"calc(50% - 10px)";
    }
    @media @mobile {
        flex: 0 1 ~"calc(100% - 10px)";
    }
    &:after {
        content: '';
        background-image: url(/img/icons/map_pin.svg);
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        display: block;
        margin-left: auto;
        justify-self: flex-end;
    }
}

.calendar-filter__button-sports {
   .calendar-filter__button;
}

.calendar-filter__button-icon {
    display: none !important;
    margin-right: 10px;
}

.calendar-filter__button.active {
    opacity: 1;
    box-shadow: 0 0 0 3px rgba(255,255,255,.6);
    .calendar-filter__button-icon {
        display: block !important;
    }
}

.calendar-clear__button {
    .button;
    flex: 0 1 ~"calc(50% - 10px)";
    /*@media @desktop-wide {
        flex: 0 1 ~"calc(33.3333% - 10px)";
    }*/
    @media @desktop-wide {
        flex: 0 1 ~"calc(50% - 10px)";
    }

    @media @mobile {
        flex: 0 1 ~"calc(100% - 10px)";
    }
    
}



// Sports calendar
.calendar--sports {
    .input-container__label {
        background-color: @c-yellow;
        color: @c-body;
    }
    .select-container:before {
        border-color: @c-yellow;
    }
   .fc {
        .fc-view {
            .fc-day-header {
                background-color: @c-yellow;
                color: @c-body;
            }
            .fc-event {
                .fc-content {
                    padding-left: 0;
                    &:before {
                        content: '';
                        display: none;
                    }
                }
                .fc-title {
                    font-weight: 600;
                    span {
                        font-weight: 400;
                    }
                    span.venue {
                        color: #9D9D9D;
                        font-size: .9rem;
                    }
                }
            }
        }
        .fc-toolbar {
            .fc-button-active {
                background-color: @c-yellow !important;
            }
        }
   }
}


// full calendar styling
.fc {
    .fc-view {
        margin: 0 -10px;
        table {
            border-collapse: separate;
            border-spacing: 5px 0;
        }
        .fc-day-header {
            background-color: @c-blue;
            color: #FFF;
            padding: 10px;
            text-transform: uppercase;
            font-weight: 400;
            a {
                display: flex;
                justify-content: space-between;
                text-decoration: none;
            }
            span {
                font-weight: 600;
            }
        }
        th, td {
            border-width: 0 !important;
            margin: 0 10px;
        }
        .fc-day {
            background-color: #FFF;
           
        }
        .fc-event {
            border-radius: 0;
            background: none;
            border: 0;
            color: @c-body;
            padding: 5px;
            font-size: 1rem;
            border-bottom: 2px solid @c-light-grey;
            margin: 5px 10px;
            .fc-content {
                padding-left: 25px;
                position: relative;
                &:before {
                    content: '\e901';
                    font-family: 'icomoon';
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 1.2rem;
                }
            }
            .fc-title {
                white-space: normal;
            }
            &.panel--whatson {
                .fc-content {
                    &:before {
                        color: @c-whatson;
                    }
                }
            }
            &.panel--country {
                .fc-content {
                    &:before {
                        color: @c-countryside;
                    }
                }
            }
            &.panel--libraries {
                .fc-content {
                    &:before {
                        color: @c-libraries;
                    }
                }
            }
            &.panel--culture {
                .fc-content {
                    &:before {
                        color: @c-culture;
                    }
                }
            }
        }
    }
    .fc-toolbar {
        flex-wrap: wrap;
        .fc-button {
            background-color: #FFF;
            padding: 2px 5px;
            color: @c-body;
            border: 0;
            margin: 0 5px;
            text-transform: uppercase;
            font-size: .8rem;
        }
        .fc-dayGridMonth-button, .fc-dayGridWeek-button, .fc-dayGridDay-button {
            padding: 2px 10px;
        }
        .fc-button-active {
            background-color: @c-blue !important;
        }
        .fc-center {
            padding-right:50px;
            @media @tablet {
                
                flex: 1 0 100%;
                margin: 10px 0;
               
                h2 {
                    text-align: left;
                }
            }
        }
    }
    h2 {
        text-transform: uppercase;
        font-weight: 400;
    }
    // Month view
    .fc-dayGridMonth-view {
        .fc-row.fc-week {
            min-height: 120px !important;
            border-bottom: 1px solid #eee;
            .fc-event {
                border: 0;
                .fc-content {
                    padding-left: 20px;
                    &:before {
                        font-size: .8rem;
                        top: 4px;
                    }
                }
                .fc-title {
                    font-size: .9rem;
                }
            }
        }
    }
}
.fc-unthemed .fc-popover {
    height: 350px;
    overflow: scroll;
    top:-40px;
}

.show-calendar{
    cursor:pointer; 
    
}

#showCalendar {
    background-color: #2FB9EC;
    padding: 2px 5px;
    color: #FFF;
    border: 0;
    margin: 0 5px;
    text-transform: uppercase;
    font-size: .8rem;
    width: 100%;
    max-width: 110px;
    display: none;
    text-align: center;
    border-radius: 3px; 
}

