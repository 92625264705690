.carousel {
   position: relative;
}

.carousel__container {
   .container;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
}

.carousel__container--parent {
   .container;
   @media @desktop-wide {
      padding: 0;
   }

   @media @mobile{
       margin-bottom:40px;
   }
}

.carousel__content {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   padding: 55px 0 100px;
   color:#fff; 
}
.carousel__content--dark {
    position: absolute;
    top: 0;  
    left: 0;
    bottom: 0;
    right: 0;
    padding: 55px 0 100px;
    color: black; 
}

.carousel__slide {
   min-height: 550px;
   width: 100%;
   padding-top: 35%;
   position: relative;
   background-color: #6d6d6d;
   color: #FFF;
   @media @tablet {
      min-height: 450px; 
   }
   @media @mobile{
       min-height:200px;
   } 
}

.carousel__title {
   text-transform: uppercase;
   // font-weight: 300;
   max-width: 500px;
   font-size: 3.4375rem;
   font-weight: 400;
   @media @mobile {
      font-size: 1.5rem;
   }
   p {
      .p--reset;
   }
}

.carousel__text {
   font-size: 1.625rem;
   p {
      .p--reset;
   }
   @media @mobile{
       font-size:1rem;
   }
}

.carousel__link-light {
    color: #ffffff !important;
    font-size: 1.625rem;
    display: block;

    @media @mobile {
        font-size: 1rem;
    }
}
.carousel__link-dark {
    color: black !important;
    font-size: 1.625rem;
    display: block;

    @media @mobile {
        font-size: 1rem;
    }
}
.carousel__link {
    color: @c-yellow !important;
    font-size: 1.625rem;
    display: block;
    @media @mobile{
        font-size:1rem;
    }
}

.carousel__img {
   display: block;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   object-fit: cover;
   height: 100%;
}

// override
.owl-carousel {
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 30px;
        // left: 50%;
        right: auto;
        // margin-left: -(@container-width / 2) + @content-padding;
        .owl-dot {
            background: none;
            border: 0;
            outline: none;
            margin: 0 5px;
            padding: 0;

            &.active {
                span {
                    background: @c-sport;
                    border-color: @c-sport;
                }
            }

            span {
                background: none;
                border: 2px solid #FFF;
            }
        }

        @media @mobile {
            bottom: -30px;
            left: ~"calc(50% - 53px)";
       
            .owl-dot {
         

                &.active { 
                    span {
                        background: @c-sport;
                        border-color: @c-sport;
                    }
                }

                span {
                    background: none;
                    border: 2px solid lightgrey;
                }
            }
        }
    }
}

