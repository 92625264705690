html, body {
    font-family: @font-sans-serif;
    margin: 0;
    padding: 0;
    color: @c-body;
    font-size: 16px;
    background-color: #FFF;
    // min-height: stretch;
    @media @mobile {
        font-size: 14px;
    }
    &.no-scroll {
        overflow-y: hidden;
    }
}

// body {
//     min-height: 100vh;
//     min-height: fill-available
// }

// body.nav--open {
//     .no-scroll;
// }

.wrapper {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   position: relative;
   overflow-x: hidden;
   min-height: stretch;
//    padding-top: @header-height;
}

main {
   flex: 1 0 auto;
}
