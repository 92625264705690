// Page colours
body.whats-on {
    .body-color(@c-whatson);
}

body.venues {
    .body-color(@c-venues);
}

body.libraries {
    .body-color(@c-libraries);
}

body.culture {
    .body-color(@c-culture);
}

body.countryside-outdoor {
    .body-color(@c-countryside);
}

body.dean-castle--theme1 {
    .dean-castle(@c-dean--delta-green,@c-dean--blue,@c-dean--delta-green,@c-dean--delta-green,#fff,@c-dean--blue,@c-dean--blue,@c-dean--delta-green,@c-dean--blue,#fff,@c-dean--blue,@c-dean--blue);
}

body.dean-castle--theme2 {
    .dean-castle(@c-dean--blue,@c-dean--green,@c-dean--delta-green,@c-dean--delta-green,#000,#fff,@c-dean--blue,@c-dean--delta-green,@c-dean--blue,#000,@c-dean--blue,@c-dean--blue);
}

body.dean-castle--theme3 {
    .dean-castle(@c-dean--silver,@c-dean--silver-blue,@c-dean--silver,@c-dean--sauvignon,#fff,@c-dean--coal,@c-dean--silver-blue,@c-dean--sauvignon,#000,#000,#000,@c-dean--silver-blue);
}

body.dean-castle--theme4 {
    .dean-castle(@c-dean--gold,@c-dean--deep-gold,@c-dean--gold,@c-dean--gold,#fff,@c-dean--coal,@c-dean--deep-gold,@c-dean--coal,#fff,#000,#000,@c-dean--deep-gold);
}

body.culture .header-banner {
    background-color: lightgray;
}

body.sport-fitness {
    .body-color(@c-sport);

    .breadcrumb {
        background-color: fade(@c-sport, 40%);
        color: @c-body;
    }

    .basic__content--heading {
        color: @c-body;
    }

    .subnav {
        background-color: lighten(@c-yellow, 35%);
    }

    .subnav-heading__text {
        color: @c-body;
    }

    .subnav {
        .owl-prev, .owl-next {
            color: @c-body;
        }
    }

    .subnav__content {
        color: @c-body;

        .icon {
            color: inherit;
        }
    }

    a[href^="mailto:"]:not(.event-email) {
        color: black !important;
    }

    .button--content {
        color: black !important;
    }
}

// mixin
.body-color(@color: @c-light-grey) {
    /*a[href^="mailto:"]{
        background-color:@color;
        color:#FFF;
    }*/
    .breadcrumb {
        // background-color: fade(@color, 70%);
        background-color: @color;
    }

    .subnav {
        background-color: lighten(@color, 55%);

        .section-heading {
            &:before, &:after {
                border-color: lighten(@color, 35%);
            }
        }

        .owl-prev, .owl-next {
            color: @color;
        }

        .section-heading__text {
            color: @color;
        }
    }

    .details-panel__heading {
        border-bottom: 2px solid @color;
    }

    .details-heading__text {
        color: @color;
    }

    .subnav-heading {
        &:before, &:after {
            border-top: 2px solid lighten(@color, 30%);
        }
    }

    .subnav-heading__text {
        color: darken(@color, 5%);
    }

    .subnav__item {
        background-color: @color;
    }

    .basic__content--heading {
        color: @color;
    }

    .event__details--panel {
        h2 {
            color: @color;
        }
    }

    .event__book--button {
        background-color: @color;
    }

    .event__details--header {
        border-bottom: 2px solid @color;
    }

    .event__venueInfo--link {
        color: @color;
    }

    .header-banner {
        background-color: lighten(@color, 30%);
    }

    .venue__details--header {
        border-bottom: 1.3px solid @color;
    }

    .venue__details--subItem {
        border-top: 1.3px solid lighten(@color,25%);
        margin-top: 5px;

        &:first-of-type {
            border-top: 0;
        }
    }

    .popup__title {
        color: @color;
    }

    .facility__list--subItem {
        border-top: 1.3px solid lighten(@color,25%);
        margin-top: 5px;

        &:first-of-type {
            border-top: 0;
        }
    }

    .heading--small {
        color: @color;
    }

    .icon {
        color: @color;
    }

    .button--content {
        background-color: @color;
    }
}

.dean-castle(@h1: @c-dean--delta-green, @main-color: @c-dean--green,@secondary-color: @c-dean--delta-green,@light-color: @c-dean--delta-green, @text-color: #fff, @body-color: @c-dean--blue, @nav-color: @c-dean--delta-green, @nav-bg: @c-dean--blue, @nav-text: @c-dean--delta-green,@breadcrumb: #fff, @button-color: @c-dean--blue, @heading-color: @c-dean--blue) {
    .breadcrumb {
        // background-color: fade(@color, 70%);
        background-color: @main-color;
        color: @breadcrumb;

        a {
            color: @breadcrumb;
        }
    }

    .header-banner {
        background-color: @light-color;
    }

    .button {
        background-color: @secondary-color;
        color: @button-color;

        a {
            color: @button-color;
        }
    }

    h1, .sub-heading__text, h3:not(.footer__heading) {
        color: @h1;
    }

    .nav__item {
        color: @c-dean--blue;
    }

    .nav__link:not(.nav__link--dropdown) {
        &:after {
            border-color: @main-color;
        }
    }

    .nav__dropdown--level2 {
        background-color: @nav-color;

        .nav__link--dropdown {
            background-color: @nav-bg;
            color: @nav-text;

            &:after {
                border-bottom: 0;
            }
        }
    }

    .venue__details--panel {
        background-color: @light-color;

        p {
            color: #3E3B37;
        }

        h2 {
            color: @heading-color;
        }
    }

    body {
        background-color: @body-color;
    }

    main {
        background-color: @body-color;

        p, a {
            color: @text-color;
        }

        a[href^="mailto:"]:not(.event-email) {
            color: @text-color;

            &:before {
                color: @text-color;
            }
        }
    }
}
// Backgrounds and text
.c-whatson--bg {
    background-color: @c-whatson;
    color: #FFF;
}

.c-whatson--text {
    color: @c-whatson;
}

.c-venues--bg {
    background-color: @c-venues;
    color: #FFF;
    // &.panel-grid__withBackground {
    //     background-color: darken(@c-venues, 10%);
    // }
}

.c-venues--text {
    color: @c-venues;
}

.c-libraries--bg {
    background-color: @c-libraries;
    color: #FFF;
    // &.panel-grid__withBackground {
    //     background-color: darken(@c-libraries, 15%);
    // }
}

.c-libraries--text {
    color: @c-libraries;
}

.c-culture--bg {
    background-color: @c-culture;
    color: #FFF;
}

.c-culture--text {
    color: @c-culture;
}

.c-culture--bg-museums {
    background-color: lighten(@c-culture, 15%);
}

.c-culture--bg-exhibitions {
    background-color: lighten(@c-culture, 15%);
}

.c-culture--bg-theatre {
    background-color: @c-venues;
}

.c-culture--bg-music {
    background-color: @c-venues;
}

.c-country--bg {
    background-color: @c-countryside;
    // &.panel-grid__withBackground {
    //     background-color: darken(@c-countryside, 15%);
    // }
}

.c-country--text {
    color: @c-countryside;
}

.c-sport--bg {
    background-color: @c-sport;
    color: @c-body;
    // &.panel-grid__withBackground {
    //     background-color: darken(@c-sport, 25%);
    // }
    path {
        fill: @c-body;
    }
}

.c-sport--text {
    color: @c-sport;
}

.c-dark--bg {
    background-color: @c-body;
    color: #FFF;
}

.c-dark--text {
    color: @c-body;
}

.c-white--text {
    color: #FFF;
}