﻿.event__details--panel {
    background-color: #F1EDE9;
    padding: 0px 0 15px;
}

.event__panel--heading {
    color: #0D1E46 !important;
}

.event__icon {
    width: 15px;
    height: 15px;
    float: right;
}

.event__details--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 15px 0px;
    list-style-type: none;
    @media @desktop {
        justify-content: center;
        padding-top:15px;
    }
    @media @mobile {
        width: 100%; 
        padding: 0px 0px 15px 0px;
        flex-direction: column;
        padding-top:15px;
    }
}

.event__details--item {
    background-color: #FFFFFF;
    padding: 10px;
    max-width:350px;
    border-radius: 6px;
    min-height: 100px;
    flex: 1 0 15%;
    margin: 0 5px;
    color: #3E3B37;
    flex-wrap: wrap;
    @media @desktop {
        flex: 0 1 ~"calc(33.333% - 10px)";
        margin-bottom: 10px;
    }
    @media @tablet {
        flex: 0 1 ~"calc(50% - 10px)";
        min-height: 135px;
        max-width:500px;
    }
    @media @mobile {
        margin-bottom: 10px;
        flex: 0 1 ~"calc(100% - 10px)";
        min-height: 110px;
        max-width:500px;
    }
}

.event__book--button {
    background-color: #0D1E46;
    /*padding: 30px;*/
    padding-top: 50px;
    border-radius: 6px;
    text-align: center;
    max-width:350px;
    /*min-height: 100px;*/
    flex: 1 0 15%;
    margin: 0 5px;
    position:relative;
    @media @desktop {
        flex: 0 1 ~"calc(33.333% - 10px)";
        margin-bottom: 10px;
        padding: 30.5px;
        max-width:500px;
       
    }
    @media @tablet {
        flex: 0 1 ~"calc(50% - 10px)";
        padding: 29.5px;
        max-width:500px;
    }
    @media @mobile {
        margin-bottom: 10px;
        flex: 0 1 ~"calc(100% - 10px)";
        padding: 20.5px;

    } 
}

.event__book--link {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    padding-top: 40px;

    @media @tablet {
        
        padding: 30px;
    }

    @media @mobile {
        padding: 25px;
    }
}

.event__book--icon {
    width: 25px;
    height: 25px;
}


.event__details--header {
    border-bottom: 2px solid #0D1E46;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.event__details--heading {
    font-size: small;
    font-weight: 600;
    margin: 0;
    color: #3E3B37;
}


.event__venueInfo--link {
    // color: #0D1E46;
    font-size: small;
    font-weight: 600;
    text-decoration: underline;

}

.long-link{
    word-wrap:break-word;
    word-break:break-all;
    display: inline-block;

}
.event__book--link2{
    color:white; 
    font-size:1.1rem;
}