﻿.search-result__list{
    list-style-type:none;
  padding-left:0;
}

.search-result__listing {
    text-decoration: none;
    font-size: 1.2rem;
    padding: 15px;
    background-color:#EBEBEB ;
    margin:5px;
    text-decoration:none;
}

.search-link {
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.search-result__title {
    text-align: left !important;
}
.search-box {
    /*border: 1px solid grey;*/
    float: left;
    margin: 0;
    /*border-radius: 7px 0 0 7px;*/
    /*height:10px;*/
    background-color:#EBEBEB;
    margin-right:5px;
    width:70%; 
    height:35px;
    @media @desktop{
        margin-right:0; 
    }
}

.search-button {
    /*border: 1px solid grey;*/
    float: right;
    margin: 0;
    /*width: 50px;*/
    /*border-radius: 0 7px 7px 0;*/
    /*background-color: #2FB9EC;*/
    /*height:10px;*/
    color: #3C3C3A;
    background-color: #EBEBEB;
    font-weight:600;
    width:29%; 
    height:35px;
    padding:5px; 
    
}
.search-container{
    /*margin-top:50px;*/
    /*margin-bottom:70px;*/ 
    position:absolute;
    top:10px; 
    left:50%; 
    margin-left: -130px; 
    max-width:700px; 
    width:100%; 
    @media @ipadPro{
        margin-left: -220px; 
    }
    @media @desktop{
       top:0;
       left:0;
       right:0; 
       margin-left:0; 
       max-width:none; 
    }
}
