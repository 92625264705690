.ie {
    .header__logo-link {
        margin-right: auto;
        display: block;
    }
}

.ie--11 {
    .panel {
        flex: 0 1 23%;

        @media @desktop {
            flex: 0 1 23%;
        }
    }

    img {
        font-family: 'object-fit: cover; object-position: center;';
    }

    .panel-grid {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .panel-grid__panel {
        min-height: 250px;
        // width: 32%;
        flex: 1 0 14%;
        margin: 0 5px 10px;
        max-width: 40%;

        @media @desktop {
            width: 48%;
        }

        @media @mobile {
            width: 100%;
        }
    }

    .panel-grid__panel--wide {
        flex: 1 0 28%;
        max-width: none;
    }

    .panel-grid__panel--large {
        flex: 1 0 28%;
        max-width: none;
        // min-height: 500px;
    }

    .calendar-filter__button, .calendar-filter__button-sports {
        flex: 0 1 40%;

        @media @desktop-wide {
            flex: 1 1 44.3%;
        }

        @media @tablet {
            flex: 1 1 40%;
        }

        @media @mobile {
            flex: 1 1 100%;
        }
    }

  

    .event__details--item {
        max-width:none;
        padding: 20px;
    }

    .event__book--button {
        padding: 20px;
        color: black;
    }

    .event__book--link {
        padding-top: 50px;
    }

    .event__book--icon {
        margin-top: 40px;
    }
}

.ie--10 {
    .nav__item--parent {
        display: flex;
        align-items: flex-end;
        .nav__link {
            display: block;
        }
    }
    .panel-grid {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .panel-grid__panel {
        min-height: 250px;
        // width: 32%;
        flex: 1 0 14%;
        margin: 0 5px 10px;
        max-width: 40%;
        @media @desktop {
            width: 48%;
        }
        @media @mobile {
            width: 100%;
        }
    }

    .panel-grid__panel--wide {
        flex: 1 0 28%;
        max-width: none;
    }

    .panel-grid__panel--large {
        flex: 1 0 28%;
        max-width: none;
        // min-height: 500px;
    }
}