@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?vt7v14');
  src:  url('../fonts/icomoon.eot?vt7v14#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?vt7v14') format('truetype'),
    url('../fonts/icomoon.woff?vt7v14') format('woff'),
    url('../fonts/icomoon.svg?vt7v14#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    color: inherit;
  }
}

.icon-close:before {
    content: "\e906";
}

.icon-arrow:before {
    content: "\e904";
}

.icon-tick:before {
    content: "\e905";
}

.icon-clock:before {
    content: "\e900";
   
}

.icon-map_pin:before {
    content: "\e901";
  
}

.icon-question:before {
    content: "\e902";
   
}

.icon-ticket:before {
    content: "\e903";
  
}
