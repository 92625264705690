﻿.accordion-box {
    padding-bottom:10px;

    margin:0 auto;
}
.accordion__header {
    display: flex;
    justify-content:space-between;
    background-color: #EBEBEB;
    width:100%; 
    //min-height: 120px;
    cursor: pointer;
    @media @mobile {
        /*max-height:100px;
        min-height:100px;*/
    } 
   
}
.accordion__title {
    margin: 0 0 5px;
    font-weight: 100;
    padding-right: 10px;
    font-size: 1.6rem;
    line-height: 1;
    @media @mobile {
        font-size:1.1rem;
    }   
}
.accordion__titles {
    position: relative;
    display: flex;
 //   flex-direction: column;
    justify-content: space-between;
    padding: 10px 0 10px 15px;
    width: 100%;
}
.accordion__img {
    
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position:center; 
    
}
.accordion__img--container {
    min-width: 120px;
    max-width: 120px;
    max-height: 120px;
    min-height: 120px;

    @media @mobile {
        /*min-width: 100px;
        max-width: 100px;
        max-height: 100px;
        min-height: 100px;*/
    }
}

.accordion__button {
    cursor: pointer;
    background-color: #EBEBEB;
    // border: solid 1px white;
    text-transform: uppercase;
    // width: 120px;
    // min-height:24px;
    color: @c-body;
    font-size: 1rem;
    // padding: 5px 5px 5px 5px;
    //  min-width: 80px;
    word-wrap: break-word;
    margin: 0;
    .transition;

    &:after {
        content: '\e904';
        font-family: 'icomoon';
        top: 50%;
        right: 60%;
        transform: translateY(-50%) rotate(270deg);
        position: absolute;
        pointer-events: none;
        font-size: 1.2rem;
       
    }

    &:hover {
        background-color: #EBEBEB; 
    }
}

.accordion-box.open .accordion__button {

    &:after {
        //content: '\e906';
        transform: translateY(-50%) rotate(90deg);
    }
}

.accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: all ease .4s;

    p {
        text-align: left;
    }
}

.accordion-box.open .accordion__content {
    max-height: 1000px;
    padding: 20px 0;
    p{
        text-align: left;
    }
}

.small-heading {
    padding-top: 15px;
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    text-transform: uppercase;
    padding:0;
    margin:0;
    float:left;
    padding-bottom: 40px;
    
}

.heading-container{
   
    padding:40px 0px 35px 0px;
    margin: 0 auto; 
    max-width:800px;
    
    

}