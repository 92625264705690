.row {
  width: auto;
  margin: 0 -@layout-padding;
  display: flex;
  flex-wrap: wrap;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.container {
  width: 100%;
  max-width: @container-width;
  margin: 0 auto;
  position: relative;
  padding: 0 @content-padding;
  &--static {
    position: static;
  }
  @media @mobile {
    padding: 0 20px;
  }
}