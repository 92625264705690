﻿.venues {

}

.venues__filter-box {
    background-color: @c-super-light-grey;
    padding: 20px 0;
    margin: 30px 0;
}

.venues__button-container {
    display: flex;
    @media @tablet {
        flex-wrap: wrap;
    }
}

.venues__button {
    flex: 1;
    font-family: "agenda",sans-serif;
    
    &:after {
        content: '\e901';
        font-family: 'icomoon';
        // background-image: url(/img/icons/map_pin.svg);
        // background-repeat: no-repeat;
        width: 25px;
        // height: 25px;
        display: block;
        margin-left: 20px; 
    }
    @media @tablet {
        flex: 0 1 45%;
    }
    @media @mobile {
        flex: 0 1 100%;
    }
}

.map-panel {
    min-height: 400px;
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
}

.autocomplete-items {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    background-color: #FFF;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    z-index: 50;
    overflow: hidden;
    div {
        padding: 10px;
        &:hover {
            background-color: #efefef;
        }
    }
}

// Venue map pop up

.map__popup {
    width: 100%;
    max-width: 210px;
    height: 100%;
    max-height: 150px;
    z-index: 2;
    position: absolute;
    display: none;
    background-color: white;
    /*border:1px solid black;*/
    border-radius:6px;
    right:380px;
    top:80px;
}

.map__text{
    margin:0; 
    font-size:medium; 
}

.popup__title {
    background-color: white;
    /*color: white;*/
    text-align:center; 
}

.popup__title-text {
    /*color: white;*/
    
    font-size: large;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 0;
    border-bottom: 0.5px solid black;
    width: 95%;
}

.popup__mainInfo{
    padding-left:10px; 
}

.popup__column1{
    width:50%;
    float:left; 
}
.popup__column2 {
    width: 50%;
    float:right; 
}

.button__close {
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    color: black;
    
}

.c-culture--bg-museums{
    background-color:red; 
}

.event__link-container{
    align-content:center; 
    text-align:center;
}
.event-link {

}

/*#venue-mobile {
    display: none;

    @media @mobile {
        display:block; 
    }
}
#venue-desktop {
    display: block;

    @media @mobile {
        display: none;
    }
}*/
.venue-details__closed-dates{
    list-style-type: none;
    padding-left: 0;
}
.venue-details__closed-date{
    padding-left: 0;
    &:before{
        content: "-";
        margin-right: 5px;
    }
}