.panel__row {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -10px;
}

.panel {
    box-shadow: inset 0 0 0 2px @c-light-grey;
    flex: 0 1 ~"calc(25% - 20px)";
    margin: 0 10px 20px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .transition;

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translateX(-50%);
        width: 80%;
        height: 8px;
        background-color: @c-dark;
        z-index: 10;
        border-radius: 3px 3px 0 0;
    }

    @media @desktop {
        flex: 0 1 ~"calc(33.3333% - 20px)";
    }

    @media @tablet {
        flex: 0 1 ~"calc(50% - 20px)";
    }

    @media @mobile {
        flex: 0 1 100%;
    }

    &:hover {
        transform: translateY(-5px);

        .panel__img {
            transform: scale(1.02);
        }
    }

    &--product {
        .product-panel__price {
            font-size: 1.125rem;
            font-weight: 400;
            letter-spacing: 1px;

            margin: 0;
        }

        .product-panel__description {
            font-size: 1.125rem;
            font-weight: 400;
            letter-spacing: 1px;

            margin-bottom: 10px;
        }

        a{
            word-break: break-all;
        }
    }
}

.panel--card {

}

.panel--event {

}

.panel__img-container {
    // height: 150px;
    position: relative;
    background-color: @c-light-grey;
    overflow: hidden;
    padding-top: 55%;
}

.panel__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
    will-change: transform;
    .transition;
}

.panel__content {
    text-align: center;
    padding: 20px;
}

.panel__title {
    font-size: 1.5625rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 10px;
    &:empty {
        margin-bottom: 0;
    }
}

.panel__subtitle {
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
}

.panel__text {

}

// colours
.panel--libraries {
    &:after {
        background-color: @c-libraries;
    }
}

.panel--sport {
    &:after {
        background-color: @c-sport;
    }
}

.panel--culture {
    &:after {
        background-color: @c-culture;
    }
}

.panel--country {
    &:after {
        background-color: @c-countryside;
    }
}

.panel--venues {
    &:after {
        background-color: @c-venues;
    }
}

.product-filters__container {
    display: flex;
    flex-wrap: wrap;
    .product-filter__button{
        flex:  1;
        background-color: @c-purple;

        @media @mobile{
            flex-basis: 100%;
        }
        &:last-of-type{
            background-color: @c-blue;
        }
    
    }
}