.news-navigation {
    display: flex;
    justify-content: space-between;
    margin: 30px -5px;
    @media @tablet {
        flex-wrap: wrap;
    }
}

.news-navigation__button {
    .button;
    background-color: @c-light-grey;
    color: @c-body;
    text-transform: uppercase;
    width: 100%;
    max-width: 250px;
    justify-content: center;
    padding: 15px;
    &:after {
        content: '\e901';
        font-family: 'icomoon';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        @media @mobile {
            position: relative;
            top: auto;
            right: auto;
            transform: none;
            margin: 0 0 0 10px;
        }
    }
    @media @tablet {
        flex: 1;
        max-width: none;
    }
}



.news-navigation__button--home {
    @media @tablet {
        flex: 1 0 ~"calc(100% - 10px)";
        order: -1;
    }
}

.news-navigation__button--previous {
    &:after {
        content: '\e904';
        right: auto;
        left: 15px;
        @media @mobile {
            left: auto;
            margin: 0 10px 0 0;
        }
    }
    @media @mobile {
        flex-direction: row-reverse;
    }
}

.news-navigation__button--next {
    &:after {
        content: '\e904';
        transform: translateY(-50%) scaleX(-1);
        @media @mobile {
            transform: scaleX(-1);
        }
    }
}