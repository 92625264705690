﻿.activity__popup {
    width: 100%;
    max-width: 330px;
    height: 100%;
    max-height: 350px;
    z-index: 2;
    position: absolute;
    display: none;
    background-color: lightgray;
    /*border:1px solid black;*/
    border-radius: 6px;
    right: 500px;
    top: 240px;

    @media @tablet{
        right: 0;
        left: 5%;
        top: 60%; 
    }
}

.popup__title-activity{
    text-align:center; 
    background-color: lightgray; 
}
#mail-link{
    word-break: break-all;
}